import {useQuery, useQueryClient} from "react-query";
import React, {useEffect, useState} from "react";
import RAQAClient from "../../../network/api/RaqaClient";
import {Typography, Button, Col, message, Result, Row, Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import RaqaClient from "../../../network/api/RaqaClient";
import Dragger from "antd/es/upload/Dragger";
import {useHistory} from "react-router-dom";

const { Title, Paragraph, Text, Link } = Typography;

const TanImportPage = (props) => {

    let history = useHistory();

    const [uploadRequest, setUploadRequest] = useState({
        uploadFinished: false,
        uploadSuccess: false,
        usersImported: 0,
        emailsSent: 0,
    });

    const redoUpload = (e) => {
        e.stopPropagation();

        setUploadRequest({
            uploadFinished: false,
            uploadSuccess: false,
        });
    }
     const beforeUploadUserList = async (file) => {

        // Create the FormData for the Multipart Upload
        const formData = new FormData();
        formData.append("csv", file);

        RaqaClient.uploadUserForTanGeneration(formData)
            .then(async response => {
                console.log(response);
                if (response.status === 200) {
                    message.info("CSV User Upload erfolgreich");
                    const importedUsers = response.data.length;
                    const successEmails =  response.data.filter(x => x.success === true).length;

                    setUploadRequest({
                        uploadFinished: true,
                        uploadSuccess: true,
                        emailsSent: successEmails,
                        usersImported: importedUsers,
                    });
                } else {
                    setUploadRequest({
                        uploadFinished: true,
                        uploadSuccess: false,
                        emailsSent: 0,
                        usersImported: 0,
                    });
                    message.error("Image Upload fehlgeschlagen");
                }
            })
            .catch(error => {
                setUploadRequest({
                    uploadFinished: true,
                    uploadSuccess: false,
                    emailsSent: 0,
                    usersImported: 0,
                });
                message.error("Fehler! CSV User Upload  ist fehlgeschlagen");
                console.error("Fehler! CSV User Upload ist fehlgeschlagen", error);
            });

        return false;
    }


    const { uploadFinished, uploadSuccess, usersImported, emailsSent } = uploadRequest;
    console.log("Upload Success: ", uploadSuccess)

    const subTitle = `Es wurde ${usersImported} Nutzer importiert und ${emailsSent} E-Mails mit den TAN Codes versandt.`

    if(uploadFinished === false) {
        return (
            <div>
                <Row>
                    <Col span={18} key={"listTan"}>
                        <h2>Nutzer für TAN Codes importieren</h2>
                    </Col>
                </Row>
                <Row style={{"marginTop":"10px", "marginBottom":"30px"}}>
                    <Col span={12}>
                        Sie können hier eine CSV Datei importieren, welche die Namen der Nutzer der RhApp enthält. Die Daten der Nutzer werden dann importiert
                        und jedem Nutzer automatisch ein zufällig generierter TAN Code zugewiesen. Die TAN Codes werden dann an die im Datensatz angegebene
                        E-Mail Adresse versandt.
                    </Col>
                </Row>
                <Row>
                    <Col span={12} key={"colDragger"}>
                        <Dragger
                            accept=".csv"
                            multiple={false}
                            fileList={[]}
                            beforeUpload={beforeUploadUserList}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">Klicken oder ziehen Sie die Datei zum Hochladen in diesen Bereich</p>
                            <p className="ant-upload-hint">
                                Sie können nur eine Datei hochladen. Bitte achten Sie darauf, dass die Datei in dem CSV Format
                                "Vorname,Nachname,Email" aufgebaut ist.
                            </p>
                        </Dragger>
                    </Col>
                </Row>
                <Row style={{"marginTop":"20px"}}>
                       <h3>Beispiel-Aufbau der CSV Datei: </h3>
                </Row>
                <Row style={{"marginTop":"0px", "marginBottom":"30px"}}>

                    <Paragraph>
                        <pre>
                        Vorname,Nachname,E-Mail<br/>
                        Max,Mustermann,max.mustermann@web.de<br/>
                            Lisa,Musterfrau,lisa.musterfrau@gmx.de<br/>
                        </pre>
                    </Paragraph>
                </Row>
            </div>
        )
    }
    else {
        return(
            <Row style={{"marginTop":"30px"}} >
                <Col span={12} key={"colResult"}>

                    {
                        uploadSuccess ?
                            <Result
                            status="success"
                            title="Liste mit Nutzern wurde erfolgreich importiert"
                            subTitle={subTitle}
                            extra={[
                                <Button type="primary" key="console" onClick={(e) => {e.stopPropagation(); history.push(`/tan`);
                                }}>
                                    Zur Tan Liste
                                </Button>,
                            ]}
                        />
                         :
                            <Result
                                status="error"
                                title="Liste konnte nicht importiert werden"
                                subTitle="Die Liste mit den Nutzern konnte nicht korrekt importiert werden. Bitte vergewissern Sie sich, dass das Format der Date korrekt ist"
                                extra={[
                                    <Button type="primary" key="console" onClick={(e) => {e.stopPropagation(); history.push(`/tan`);
                                    }}>
                                        Zur Tan Liste
                                    </Button>,
                                    <Button key="redo" onClick={redoUpload}>Erneut versuchen</Button>,
                                ]}
                            />
                    },


                </Col>
            </Row>
        )
    }
}

export default TanImportPage;
